import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import GatsbyImage from 'gatsby-image';
import { CTALink } from '../CTA';
import { sanitizer, useCSSMotion, useCTAClickTracking, useSectionTimeSpent } from '../../util';
import { ReactComponent as WavesSvg } from '../../assets/svgs/waves.svg';
import './ImageCta.scss';

const ImageCta = ({
  data: {
    title,
    description,
    image,
    imagePosition = 'right',
    backgroundColor = 'green',
    withWaves = true,
    ctaLink,
    name,
    id,
  },
}) => {
  const containerRef = useRef(null);
  const motionState = useCSSMotion(containerRef);
  const handleCtaClick = useCTAClickTracking(name);

  useSectionTimeSpent(name, containerRef);

  return (
    <div id={id} className={classnames('ImageCta', motionState)} ref={containerRef}>
      <div className={classnames('flex-container', imagePosition)}>
        {image?.localFile && (
          <div className="image-container">
            <GatsbyImage className="image" fluid={image?.localFile.childImageSharp.fluid} alt={image?.altText} />
          </div>
        )}
        <div className={classnames('content-container', backgroundColor, { withWaves })}>
          <h3 className="title">{title}</h3>
          <div className="description" dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
          {ctaLink?.url && (
            <CTALink
              onClick={handleCtaClick}
              link={ctaLink.url}
              text={ctaLink.title}
              ariatext={ctaLink.title}
              motionState={motionState}
              white={true}
            />
          )}

          {withWaves && (
            <div className="waves-container">
              <WavesSvg className="waves" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const fragments = graphql`
  fragment ImageCta on WpPage_Flexiblelayout_FlexibleChildren_ImageCta {
    title
    description
    image {
      ...FluidImage
    }
    imagePosition
    backgroundColor
    withWaves
    ctaLink {
      url
      title
      target
    }
    id
    jumpToLinkTitle
    name
  }

  fragment ImageCta_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_ImageCta {
    title
    description
    image {
      ...FluidImage
    }
    imagePosition
    backgroundColor
    withWaves
    ctaLink {
      url
      title
      target
    }
    id
    jumpToLinkTitle
    name
  }
`;

export default ImageCta;
